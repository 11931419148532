<template>
    <div id="Intro" v-if="animation !== 'hide'" :class="{ rollRight: animation === 'rollRight' }">
        <div class="preload" style="display: none">
            <img :src="require('@/assets/Scene0/frontside.png')" @load="loadHandler" />
            <img :src="require('@/assets/Scene0/backside.png')" @load="loadHandler" />
            <img :src="require('@/assets/Scene0/rotating.png')" @load="loadHandler" />
        </div>
        <div class="section-title" :class="animation">
            <h1 class="title">Octave</h1>
            <div class="subtitle">
                <ul v-if="animation === 'rollLeft fadeIn' || animation === 'rollRight'">
                    <li
                        v-for="(item, index) in subtitle"
                        :key="'intro-subtitle-' + index"
                        data-aos="fade-up"
                        :data-aos-delay="200 * index"
                        data-aos-duration="800"
                    >
                        {{ item }}
                    </li>
                </ul>
            </div>
        </div>
        <div class="section-logo" :class="animation"></div>
    </div>
</template>

<script>
import { onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import AOS from 'aos'

export default {
    name: 'Intro',
    setup() {
        // page info
        const subtitle = ['由需求導向開發', '有效且有效率的打造品牌形象']

        let loadedCount = ref(0)
        let loadHandler = () => {
            console.log('img loaded')
            loadedCount.value++
        }

        let store = useStore()
        let animation = ref('init')
        function animationHandler() {
            if (store.getters.isVisited) {
                animation.value = 'hide'
            } else {
                store.commit('visit')
                setTimeout(() => {
                    animation.value = 'drop'
                    setTimeout(() => {
                        animation.value = 'drop turn'
                    }, 850)
                    setTimeout(() => {
                        animation.value = 'turn'
                    }, 1450)
                    setTimeout(() => {
                        animation.value = 'turn rollLeft'
                    }, 2200)
                    setTimeout(() => {
                        animation.value = 'rollLeft'
                    }, 2300)
                    setTimeout(() => {
                        animation.value = 'rollLeft fadeIn'
                    }, 3200)
                    setTimeout(() => {
                        animation.value = 'rollRight'
                    }, 4800)
                    setTimeout(() => {
                        animation.value = 'hide'
                    }, 7200)
                }, 1000)
            }
        }

        watch(loadedCount, (count) => {
            if (count === 3) {
                animationHandler()
            }
        })

        onMounted(() => {
            AOS.init()
        })

        return { subtitle, animation, loadHandler, loadedCount }
    },
}
</script>

<style lang="scss" scoped>
@import '../style/indexAnimations.scss';

#Intro {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 5000;

    width: 100vw;
    height: 100vh;

    background-color: $gray-f;
    transition: 2.4s;

    &.rollRight {
        background-color: transparent;
    }
}

.section-title {
    position: absolute;
    top: calc(50vh - 6vw);

    display: none;
    height: 12vw;
    overflow: hidden;

    h1.title {
        text-align: right;
        color: $major-dark;
        font-size: 7vw;
        font-weight: 600;
    }

    .subtitle {
        position: relative;

        width: 24.5vw;
        color: $major-dark;
        text-align: left;
        text-indent: 0;
        font-size: 1.5vw;
        font-weight: 600;
        line-height: 1.4em;
        letter-spacing: 0.1em;
    }

    @mixin showTitle($left, $width) {
        left: $left;
        width: $width;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-start;
    }
    &.rollLeft {
        @include showTitle(37.5vw, 34.5vw);
        animation: rollLeftTitle 1.4s cubic-bezier(0.55, 0, 0.45, 1);
    }

    &.rollRight {
        @include showTitle(72vw, 0vw);
        animation: rollRightTitle 0.9s cubic-bezier(0.45, 0, 0.55, 0);
    }
}

.section-logo {
    position: absolute;
    left: 42.5vw;
    top: calc(50vh - 7.5vw);

    width: 15vw;
    height: 15vw;

    background: center / cover no-repeat;

    &.drop {
        background-image: url('~@/assets/Scene0/backside.png');
        animation: dropIn 1.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    }

    &.turn {
        &::after {
            content: '';

            display: block;
            width: 100%;
            height: 100%;

            background: url('~@/assets/Scene0/rotating.png') center / cover no-repeat;
        }
    }

    &.rollLeft {
        left: 30vw;

        background-image: url('~@/assets/Scene0/frontside.png');
        transform: rotate(-150deg);
        animation: rollLeft 1.4s cubic-bezier(0.55, 0, 0.45, 1);
    }

    &.rollRight {
        left: 120vw;

        background-image: url('~@/assets/Scene0/frontside.png');
        animation: rollRight 2s cubic-bezier(0.55, 0, 0.45, 1);
    }
}
</style>
