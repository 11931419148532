<template>
  <div id="ContactForm">
    <div class="main">
      <div
        class="section section-title"
        id="section-title"
        name="section-title"
      >
        <div class="title" data-aos="fade-up" data-aos-anchor="#section-title">
          <h1>聯絡我們</h1>
          <span>Contact Us</span>
        </div>
      </div>
      <div
        class="section section-form"
        data-aos="fade-up"
        :data-aos-delay="300"
        data-aos-anchor="#section-title"
      >
        <ElForm ref="form" :model="formData" :rules="formRules">
          <div class="left">
            <ElFormItem prop="name" label="姓名">
              <ElInput
                v-model="formData.name"
                placeholder="輸入用戶名"
              ></ElInput>
            </ElFormItem>
            <ElFormItem prop="phone" label="聯絡電話">
              <ElInput
                v-model="formData.phone"
                placeholder="輸入電話"
              ></ElInput>
            </ElFormItem>
            <ElFormItem prop="email" label="電子郵件">
              <ElInput
                v-model="formData.email"
                placeholder="輸入電子郵件"
              ></ElInput>
            </ElFormItem>
            <ElFormItem prop="budget" label="預算">
              <ElInput
                v-model.number="formData.budget"
                placeholder="輸入您的預算"
              ></ElInput>
            </ElFormItem>
          </div>
          <div class="right">
            <ElFormItem prop="details" label="項目內容">
              <ElInput
                v-model="formData.details"
                :autosize="{ minRows: 9 }"
                resize="none"
                type="textarea"
                placeholder="輸入項目內容"
              ></ElInput>
            </ElFormItem>
          </div>
          <br />
        </ElForm>
        <div class="button-wrap">
          <span class="button" @click="submitForm">送出</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import { ElForm, ElFormItem, ElInput, ElMessageBox } from "element-plus";
import { postContactForm } from "../service/api.js";
import AOS from "aos";

export default {
  name: "ContactForm",
  components: { ElForm, ElFormItem, ElInput },

  setup() {
    onMounted(() => {
      AOS.init();
    });
    const form = ref(null);
    const formData = reactive({
      name: "",
      phone: "",
      email: "",
      budget: "",
      details: "",
    });
    const formRules = reactive({
      name: [
        {
          required: true,
          message: "請填入您的用戶名",
          trigger: "blur",
        },
      ],
      phone: [
        {
          required: true,
          message: "請填入您的電話",
          trigger: "blur",
        },
      ],
      email: [
        {
          required: true,
          message: "請填入您的信箱",
          trigger: "blur",
        },
        {
          validator: (rule, value, callback) => {
            const mailReg =
              /\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/;

            if (mailReg.test(value)) {
              callback();
            } else {
              callback(new Error("請輸入正確email格式"));
            }
          },
          trigger: "blur",
        },
      ],
      budget: [
        {
          type: "number",
          message: "預算必須填入有效數字",
          trigger: "blur",
        },
      ],
      details: [
        {
          required: true,
          message: "請填入您的項目內容",
          trigger: "blur",
        },
      ],
    });
    const submitForm = function () {
      form.value.validate(async (valid) => {
        if (valid) {
          postContactForm(formData).then(() => {
            ElMessageBox.alert(
              "感謝您的詢問，我們將以最快的速度回覆您！",
              "已收到您的詢問",
              {
                confirmButtonText: "OK",
              }
            ).then(() => {
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
              });
              formData.name = "";
              formData.phone = "";
              formData.email = "";
              formData.budget = "";
              formData.details = "";
            });
          });
        }
      });
    };

    return { submitForm, formRules, formData, form };
  },
};
</script>

<style lang="scss" scoped>
.main {
  position: relative;
  margin: 80px 0;
}
.section-title {
  @include fullTitleSetting();
}
.section-form {
  margin: 30px auto;
  width: 90%;
}
.button-wrap {
  text-align: center;
  .button {
    display: inline-block;
    width: 50px;
    text-align: center;
    @include buttonSetting($major-dark, 30px);
  }
}

@media screen and (min-width: 640px) {
  .section-form {
    max-width: 900px;
  }
  .el-form {
    display: grid;
    grid-template-columns: 2fr 3fr;
    column-gap: 30px;
  }
}

@media screen and (max-width: 639px) {
  .el-form {
    display: block;
  }
}
</style>
