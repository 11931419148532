<template>
    <div id="Home" @wheel="scrollChange" @touchstart="recordStart" @touchend="scrollChange">
        <div
            id="Scene1"
            class="scene"
            :class="{
                active: activeScene === 1,
                inactive: activeScene !== 1,
                cw: direction === 'cw',
                ccw: direction === 'ccw',
            }"
        >
            <div id="s1-building1"></div>
            <div id="s1-building2"></div>
            <div id="s1-building3"></div>
            <div id="s1-tree1"></div>
            <div id="s1-tree2"></div>
            <div id="s1-floor" class="floor"></div>
            <div id="s1-slogan" class="slogan">
                <h2 data-aos="fade-up">
                    我們的使命是為您的平台 <br />
                    設計最佳的使用者體驗
                </h2>
                <p data-aos="fade-up" :data-aos-delay="200">
                    從 Web 到 App，我們能依照您的需求開發專屬的客製化平台，<br />
                    然而不論使用何種平台，其所呈現的形象將是品牌與用戶最重要的連結，
                    <br />
                    我們深信優秀的使用體驗勝過千言萬語。
                </p>
            </div>
            <div id="s1-vender1"></div>
            <div id="s1-vender2"></div>
            <div id="s1-vender3"></div>
            <div id="s1-vender4"></div>
            <div id="s1-human1"></div>
            <div id="s1-human3"></div>
            <div id="s1-human2"></div>
            <div id="s1-deco2"></div>
            <div id="s1-deco3"></div>
        </div>
        <div
            id="Scene2"
            class="scene"
            :class="{
                active: activeScene === 2,
                inactive: activeScene !== 2,
                cw: direction === 'cw',
                ccw: direction === 'ccw',
            }"
        >
            <div id="s2-farShelf1"></div>
            <div id="s2-farShelf2"></div>
            <div id="s2-deco1"></div>
            <div id="s2-deco2"></div>
            <div id="s2-deco3"></div>
            <div id="s2-floor" class="floor"></div>
            <div id="s2-slogan" class="slogan">
                <h2 data-aos="fade-up">
                    追蹤的平台隱藏資訊 <br />
                    創造品牌前所未有的價值
                </h2>
                <p data-aos="fade-up" :data-aos-delay="200">
                    任何平台都有其具有極高價值的隱藏資訊，
                    <br />
                    我們協助品牌追蹤這些隱藏資訊並轉譯成可採取行動的下一步，
                    <br />
                    為您的品牌創造過去未曾想像的全新價值。
                </p>
            </div>
            <div id="s2-casher"></div>
            <div id="s2-notice"></div>
            <div id="s2-fruits"></div>
            <div id="s2-shelf"></div>
            <div id="s2-human1"></div>
            <div id="s2-human2"></div>
            <div id="s2-human3"></div>
            <div id="s2-light1"></div>
            <div id="s2-light2"></div>
        </div>
        <div
            id="Scene3"
            class="scene"
            :class="{
                active: activeScene === 3,
                inactive: activeScene !== 3,
                cw: direction === 'cw',
                ccw: direction === 'ccw',
            }"
        >
            <div id="s3-stairs"></div>
            <div id="s3-deco"></div>
            <div id="s3-slogan" class="slogan">
                <h2 data-aos="fade-up">
                    提供最完整的服務鏈 <br />
                    為您簡化開發的溝通成本
                </h2>
                <p data-aos="fade-up" :data-aos-delay="200">
                    從平台的使用者研究、介面與流程設計到軟體撰寫、數據分析與模型開發，
                    <br />
                    我們提供最完備的售前與售後服務，簡化平台開發的溝通成本，
                    <br />
                    即刻與我們聯繫，我們將為您的打造最契合品牌的完美平台。
                </p>
            </div>
            <div id="s3-window"></div>
            <div id="s3-picture"></div>
            <div id="s3-desk"></div>
            <div id="s3-smallComp"></div>
            <div id="s3-human"></div>
            <div id="s3-largeComp">
                <div class="web"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'

export default {
    name: 'Home',
    setup() {
        const activeScene = ref(1)
        const direction = ref('cw')
        const changingFlag = ref(false)
        let lastTouchX

        function recordStart($event) {
            console.log($event)
            lastTouchX = $event.changedTouches[0].clientX
        }

        function scrollChange($event) {
            if (changingFlag.value) return
            changingFlag.value = true
            if ($event.type === 'touchend') {
                let currTouchX = $event.changedTouches[0].clientX
                console.log(currTouchX, lastTouchX)
                if (currTouchX < lastTouchX) {
                    direction.value = 'cw'
                    activeScene.value === 3 ? (activeScene.value = 1) : (activeScene.value += 1)
                } else {
                    direction.value = 'ccw'
                    activeScene.value === 1 ? (activeScene.value = 3) : (activeScene.value -= 1)
                }
                lastTouchX = currTouchX
            } else {
                if ($event.deltaY > 0 || $event.deltaX > 0) {
                    direction.value = 'cw'
                    activeScene.value === 3 ? (activeScene.value = 1) : (activeScene.value += 1)
                } else {
                    direction.value = 'ccw'
                    activeScene.value === 1 ? (activeScene.value = 3) : (activeScene.value -= 1)
                }
            }
            setTimeout(() => {
                changingFlag.value = false
            }, 1200)
        }

        return { activeScene, direction, recordStart, scrollChange }
    },
}
</script>

<style lang="scss" scoped>
/* <===================== GENERAL SETTING =====================> */
.scene {
    div {
        position: absolute;
        background: center / contain no-repeat;
    }
    .slogan {
        h2 {
            line-height: 1.3em;
            letter-spacing: 0.02em;
            font-weight: 700;
        }
        p {
            margin-top: 0.4em;
            line-height: 1.5em;
            letter-spacing: 0.03em;
            font-weight: 500;
        }
    }
}
#Scene1 {
    background-color: #f5e5db;
    z-index: 51;
    #s1-slogan {
        h2 {
            color: $minor-dark;
            text-shadow: 5px 5px 5px rgba($color: $minor-light, $alpha: 0.1);
        }
        p {
            color: $gray-2;
            text-shadow: 0 0 10px white, 5px 5px 5px rgba($color: $minor-light, $alpha: 0.1);
        }
    }
    #s1-floor {
        width: 100vw;
        bottom: 0;
        background-color: #9e7f60;
    }
}

#Scene2 {
    background-color: #5f4f40;
    z-index: 50;
    #s2-slogan {
        h2 {
            color: $minor-light;
            text-shadow: 5px 5px 5px rgba($color: $minor-dark, $alpha: 0.5);
        }
        p {
            color: $gray-f;
            text-shadow: 0 0 10px black, 5px 5px 5px rgba($color: $minor-dark, $alpha: 0.1);
        }
    }
    #s2-floor {
        width: 100vw;
        bottom: 0;
        background-color: #c4afa0;
    }
}

#Scene3 {
    background-color: #f3ebe6;
    z-index: 49;
    #s3-slogan {
        h2 {
            color: $minor-dark;
            text-shadow: 5px 5px 5px rgba($color: $minor-light, $alpha: 0.1);
        }
        p {
            color: $gray-2;
            text-shadow: 0 0 10px white, 5px 5px 5px rgba($color: $minor-light, $alpha: 0.1);
        }
    }
    #s3-stairs,
    #s3-deco {
        opacity: 0.5;
    }

    #s3-largeComp {
        .web {
            position: absolute;
            background: top center / 90% no-repeat;
            background-image: url('~@/assets/Scene3/Web.png');
            animation: scrollWeb 10s ease-in-out infinite 1s;
            @keyframes scrollWeb {
                0% {
                    background-position-y: top;
                }
                33% {
                    background-position-y: 30%;
                }
                66% {
                    background-position-y: 60%;
                }
                100% {
                    background-position-y: 90.5%;
                }
            }
        }
    }
}
</style>

<style lang="scss" scoped>
/* <===================== PC SCROLL VIEW =====================> */
// 使用參數創造畫面
@mixin createScene($scene, $figure) {
    @each $key, $value in $scene {
        #{$key} {
            width: map-get($value, width);
            height: map-get($value, height);
            background-image: url('~@/assets/Scene#{$figure}/#{map-get($value, img)}.png');
            @if map-has-key($value, top) {
                top: map-get($value, top);
            } @else {
                bottom: map-get($value, bottom);
            }
        }
    }
}

// 移動動畫
@mixin sceneChange($direction, $prePos, $endPos, $scale, $transform: scaleX(1), $swing: '') {
    $name: #{unique-id()};
    animation: #{$direction}-#{$name} 1s ease-in-out, magnify-#{$name} 1s linear, $swing;
    @keyframes right-#{$name} {
        0% {
            right: $prePos;
        }
        100% {
            right: $endPos;
        }
    }
    @keyframes left-#{$name} {
        0% {
            left: $prePos;
        }
        100% {
            left: $endPos;
        }
    }
    @keyframes magnify-#{$name} {
        0% {
            transform: scale(1) $transform;
        }
        50% {
            transform: scale($scale) $transform;
        }
        100% {
            transform: scale(1) $transform;
        }
    }
}

// 畫面移入
@mixin activeScene($scene, $direction) {
    @each $key, $value in $scene {
        #{$key} {
            $scaleX: map-get($value, scaleX);
            $scaleX: 1 !default;
            $swing: map-get($value, swing);
            $swing: '' !default;
            $from: 100vw !default;
            @if $direction == cw {
                $from: -#{map-get($value, width)};
            }
            #{map-get($value, type)}: map-get($value, #{map-get($value, type)});
            @include sceneChange(
                map-get($value, type),
                $from,
                map-get($value, #{map-get($value, type)}),
                map-get($value, scale),
                scaleX($scaleX),
                $swing
            );
            @if $scaleX != 1 {
                transform: scaleX($scaleX);
            }
        }
    }
}

// 畫面移出
@mixin inactiveScene($scene, $direction) {
    @each $key, $value in $scene {
        #{$key} {
            $scaleX: map-get($value, scaleX);
            $scaleX: 1 !default;
            $to: 100vw !default;
            @if $direction == ccw {
                $to: -#{map-get($value, width)};
            }
            #{map-get($value, type)}: 100vw;
            @include sceneChange(
                map-get($value, type),
                map-get($value, #{map-get($value, type)}),
                $to,
                map-get($value, scale),
                scaleX($scaleX)
            );
            @if $scaleX != 1 {
                transform: scaleX($scaleX);
            }
        }
    }
}

@media screen and (min-width: 960px) {
    @import '../style/homeVariableRWD.scss';

    #Home {
        position: absolute;
        left: 0;
        top: 0;

        width: 100vw;
        height: 100vh;
        overflow: hidden;
    }

    .scene {
        position: absolute;
        z-index: 200 !important;
        width: 100%;
        height: 100%;
        overflow: hidden;
        transition: 1s;
        .floor,
        .slogan {
            transition: 1s;
        }
        .slogan {
            h2 {
                font-size: 40px;
            }
            p {
                font-size: 15px;
            }
        }
        &.active {
            opacity: 1;
            transition: 1s;
            .floor {
                opacity: 1;
            }
            .slogan {
                opacity: 1;
                transform: translateY(0);
            }
        }
        &.inactive {
            opacity: 0;
            .floor {
                opacity: 0;
            }
            .slogan {
                opacity: 0;
                transform: translateY(40px);
            }
        }
    }

    #Scene1 {
        @include createScene($Scene1, 1);
        #s1-slogan {
            right: 12vw;
            top: 24vh;
            text-align: left;
        }
        #s1-floor {
            height: 15vh;
        }
        #s1-human2 {
            width: 12vw;
            height: 16vw;
            bottom: 0vh;
            background-image: url('~@/assets/Scene1/Human2.png');
        }
        &.active {
            &.cw {
                @include activeScene($Scene1, cw);
            }
            &.ccw {
                @include activeScene($Scene1, ccw);
            }
            #s1-floor {
                animation: floorExpand 1s ease-in-out;
            }
            #s1-human2 {
                right: 100vw;
                animation: walking 40s linear infinite;
                @keyframes walking {
                    0% {
                        transform: translateX(0);
                    }
                    100% {
                        transform: translateX(115vw);
                    }
                }
            }
        }
        &.inactive {
            background-color: transparent;
            &.cw {
                @include inactiveScene($Scene1, cw);
            }
            &.ccw {
                @include inactiveScene($Scene1, ccw);
            }
            #s1-human2 {
                right: 100vw;
            }
        }
    }

    #Scene2 {
        @include createScene($Scene2, 2);
        #s2-slogan {
            left: 13vw;
            top: 26vh;
            text-align: left;
        }
        #s2-floor {
            height: 25vh;
        }
        #s2-human3 {
            width: 20vw;
            height: 20vw;
            bottom: 0vh;
            background-image: url('~@/assets/Scene2/Human3.png');
        }
        &.active {
            &.cw {
                @include activeScene($Scene2, cw);
            }
            &.ccw {
                @include activeScene($Scene2, ccw);
            }
            #s2-floor {
                animation: floorExpand 1s ease-in-out;
            }
            #s2-human3 {
                right: -25vw;
                animation: walking2 30s linear infinite;
                @keyframes walking2 {
                    0% {
                        transform: translateX(0);
                    }
                    100% {
                        transform: translateX(-125vw);
                    }
                }
            }
        }
        &.inactive {
            background-color: transparent;
            &.cw {
                @include inactiveScene($Scene2, cw);
            }
            &.ccw {
                @include inactiveScene($Scene2, ccw);
            }
            #s2-human3 {
                right: -25vw;
            }
        }
    }

    #Scene3 {
        @include createScene($Scene3, 3);
        #s3-slogan {
            left: 55vw;
            top: 24vh;
            text-align: left;
        }

        #s3-largeComp {
            .web {
                width: 70.4vh;
                height: 50.5vh;
                top: 22.4vh;
                right: 29.7vh;
            }
        }
        &.active {
            &.cw {
                @include activeScene($Scene3, cw);
            }
            &.ccw {
                @include activeScene($Scene3, ccw);
            }
        }
        &.inactive {
            background-color: transparent;
            &.cw {
                @include inactiveScene($Scene3, cw);
            }
            &.ccw {
                @include inactiveScene($Scene3, ccw);
            }
        }
    }
}
</style>

<style lang="scss" scoped>
// 使用參數創造畫面
@mixin createScene($scene, $figure) {
    @each $key, $value in $scene {
        #{$key} {
            width: map-get($value, width);
            height: map-get($value, height);
            background-image: url('~@/assets/Scene#{$figure}/#{map-get($value, img)}.png');
            #{map-get($value, type)}: map-get($value, #{map-get($value, type)});
            $scaleX: map-get($value, scaleX);
            $scaleX: 1 !default;
            $swing: map-get($value, swing);
            $swing: '' !default;
            animation: $swing;
            @if map-has-key($value, top) {
                top: map-get($value, top);
            } @else {
                bottom: map-get($value, bottom);
            }
        }
    }
}

/* <===================== PC SLIDE VIEW =====================> */
@media screen and (max-width: 959px) and (min-width: 640px) {
    @import '../style/homeVariableM.scss';

    .scene {
        position: relative;
        width: 100%;
        height: 100vh;
        .slogan {
            width: 100vw;
            left: 0;
            top: 32vh;
            text-align: center;
            h2 {
                font-size: 48px;
            }
            p {
                font-size: 16px;
            }
        }
    }

    #Scene1 {
        @include createScene($Scene1, 1);
        #s1-floor {
            height: 10vh;
        }
        #s1-human2 {
            width: 12vw;
            height: 16vw;
            bottom: 2vh;
            background-image: url('~@/assets/Scene1/Human2.png');
            right: 100vw;
            animation: walking 40s linear infinite;
            @keyframes walking {
                0% {
                    transform: translateX(0);
                }
                100% {
                    transform: translateX(115vw);
                }
            }
        }
    }

    #Scene2 {
        @include createScene($Scene2, 2);
        #s2-floor {
            height: 15vh;
        }
        #s2-human3 {
            width: 20vw;
            height: 20vw;
            bottom: 2vh;
            background-image: url('~@/assets/Scene2/Human3.png');
            right: -25vw;
            animation: walking2 30s linear infinite;
            @keyframes walking2 {
                0% {
                    transform: translateX(0);
                }
                100% {
                    transform: translateX(-125vw);
                }
            }
        }
    }

    #Scene3 {
        @include createScene($Scene3, 3);
        #s3-slogan {
            top: 42vh;
        }
        #s3-largeComp {
            .web {
                // border: 1px solid black;
                width: 26.5vh;
                height: 19vh;
                top: 10.2vh;
                right: 10.2vh;
            }
        }
    }
}

/* <===================== MOBILE VIEW =====================> */
@media screen and (max-width: 639px) {
    @import '../style/homeVariableS.scss';

    .scene {
        position: relative;
        height: 85vh;
        .slogan {
            left: 0;
            top: 25vh;
            margin: 0 5vw;
            text-align: left;
        }
    }

    #Scene1 {
        @include createScene($Scene1, 1);
        #s1-floor {
            height: 10vh;
        }
        #s1-human2 {
            width: 12vw;
            height: 16vw;
            bottom: 2vh;
            background-image: url('~@/assets/Scene1/Human2.png');
            right: 100vw;
            animation: walking 40s linear infinite;
            @keyframes walking {
                0% {
                    transform: translateX(0);
                }
                100% {
                    transform: translateX(115vw);
                }
            }
        }
    }

    #Scene2 {
        @include createScene($Scene2, 2);
        #s2-floor {
            height: 15vh;
        }
        #s2-human3 {
            width: 20vw;
            height: 20vw;
            bottom: 2vh;
            background-image: url('~@/assets/Scene2/Human3.png');
            right: -25vw;
            animation: walking2 30s linear infinite;
            @keyframes walking2 {
                0% {
                    transform: translateX(0);
                }
                100% {
                    transform: translateX(-125vw);
                }
            }
        }
    }

    #Scene3 {
        @include createScene($Scene3, 3);
        height: 110vh;

        #s3-slogan {
            top: 44vh;
        }
        #s3-largeComp {
            .web {
                width: 26.5vh;
                height: 19vh;
                top: 10.2vh;
                right: 10.2vh;
            }
        }
    }
}

@media screen and (max-width: 639px) and (min-width: 480px) {
    .slogan {
        h2 {
            font-size: 36px;
        }
        p {
            font-size: 16px;
        }
    }
}

@media screen and (max-width: 479px) {
    .slogan {
        h2 {
            font-size: 7vw;
        }
        p {
            font-size: 3.5vw;
        }
    }
}
</style>
